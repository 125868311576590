import React from 'react';

import AxiosRequest from '../apis';

// 管理员等api请求
const adminerBaseApis = ['adminer/v1/admin', 'adminer/v1/createadmin', ];

const AdminerRequestPost = ({ requestCount, reqObject, responseDataHandle }) => {
  // 新建顾客请求 只有超级管理员才可以创建管理员
  const api = [].concat(adminerBaseApis)[1];
  const method = 'post';
  const reqParams = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  )
};

AdminerRequestPost.defaultProps = {
  requestCount: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
}

const AdminerRequestPut = ({ adminerId, requestCount, reqObject, responseDataHandle }) => {
  // 修改管理员自己详情信息 put 请求
  // request请求数据
  const api = [].concat(adminerBaseApis)[0] + `/${adminerId}`;
  const method = 'put';
  const reqParams = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  )
}
AdminerRequestPut.defaultProps = {
  adminerId: 0,
  requestCount: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
};

const AdminerRequestGet = ({ requestCount, reqParams, responseDataHandle }) => {
  // 获取管理员列表
  const reqObject = {};
  const api = [].concat(adminerBaseApis)[0] + `/1`;
  const method = 'get';

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  )
};
AdminerRequestGet.defaultProps = {
  requestCount: 0,
  reqParams: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
};

const AdminerRequestDelete = ({ requestCount, adminId, responseDataHandle }) => {
  // 获取管理员列表
  const reqObject = {};
  const api = [].concat(adminerBaseApis)[1] + `/${adminId}`;
  const method = 'delete';
  const reqParams = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  )
};
AdminerRequestDelete.defaultProps = {
  requestCount: 0,
  adminId: 0,
  responseDataHandle: (respObj) => { console.log(respObj); },
};

const SupperAdminerRequestGet = ({ requestCount, reqParams, responseDataHandle }) => {
  // 获取管理员列表
  const reqObject = {};
  const api = [].concat(adminerBaseApis)[1];
  const method = 'get';

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  )
};
SupperAdminerRequestGet.defaultProps = {
  requestCount: 0,
  reqParams: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
}

const SuperAdminerRequestPut = ({ adminerId, requestCount, reqObject, responseDataHandle }) => {
  // 修改管理员自己详情信息 put 请求
  // request请求数据
  const api = [].concat(adminerBaseApis)[1] + `/${adminerId}`;
  const method = 'put';
  const reqParams = {};

  return (
    <AxiosRequest
      responseDataHandle={responseDataHandle} api={api} method={method}
      reqParams={reqParams} reqObject={reqObject} requestCount={requestCount}
    ></AxiosRequest>
  )
}
SuperAdminerRequestPut.defaultProps = {
  adminerId: 0,
  requestCount: 0,
  reqObject: {},
  responseDataHandle: (respObj) => { console.log(respObj); },
};

export {
  AdminerRequestPost,
  AdminerRequestPut,
  AdminerRequestGet,
  AdminerRequestDelete,
  SupperAdminerRequestGet,
  SuperAdminerRequestPut,
};
