import React from 'react';
import { Button } from 'antd';

// 读取更多 Button 组件

const LoadMore = ({ sendRequest, showLoadMoreButton }) => {
  return (
    showLoadMoreButton ?
      (
        <div style={{
          textAlign: 'center',
          marginTop: 12,
          height: 32,
          lineHeight: '32px',
        }}
        ><Button onClick={sendRequest}>loading more</Button>
        </div>
      )
      : null
  );
}

export default LoadMore;
