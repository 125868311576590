import React, { useState, useRef } from 'react';
import { Modal, message } from 'antd';

import OverallLoading from '../overall_loading';
import { AdminerRequestPost } from './adminers_requests';
import { CustomForm } from '../base_components/form_component';
import {
  adminerColumns,
  adminerColumnInitialValues,
  formKeyLabelObj,
  formKeySelectComponentsObj,
  passwordKeys,
  columnRulesObj,
} from './admin_columns_config';
import UploadSingleFileHandle from '../upload_files/uploadSingleFileHandle';

const notShowKeys = ["id", "avatar", "wx_openid", "add_time", "update_time", "title", "on_job"];
const disabledKeys = ["id", "wx_openid", "avatar", "add_time", "update_time",];

// 创建管理员
const AdminCreateModal = ({ modalState, setModalState }) => {
  const [spinning, setSpinning] = useState(false);
  const uploadedAvatarCosNameObj = useRef({});
  const createAdminObj = useRef({});
  const [createRequestCount, setCreateRequestCount] = useState(0);
  const [cleanAvatarCount, setCleanAvatarCount] = useState(0)
  const [initAdminFormCount, setInitAdminFormCount] = useState(0);

  const changedAdminAvatarHandle = (responseObj, fileKey) => {
    if (Object.keys(responseObj).includes('code')
      && responseObj.code === 1000
      && Object.keys(responseObj).includes('image_cos_name')) {
      uploadedAvatarCosNameObj.current = { avatar: responseObj.image_cos_name };
    }
  }

  const onFinish = (values) => {
    // 提交创建管理员
    setSpinning(true);
    createAdminObj.current = { ...values, ...uploadedAvatarCosNameObj.current };
    setCreateRequestCount(createRequestCount + 1); // 提交创建
  };

  const createResponseDataHandle = (respObj) => {
    // 提交创建管理员 返回结果
    setSpinning(false);
    if (Object.keys(respObj).includes('code')
      && respObj.code === 1000) {
      message.success('Success');
      message.success('创建管理员成功!');
      cancelHandle();
    }else {
      message.success('Error...');
      message.success('Error: 网络错误...');
    }

  }

  const cancelHandle = () => {
    // 取消操作
    uploadedAvatarCosNameObj.current = {};
    createAdminObj.current = {};
    setInitAdminFormCount(initAdminFormCount + 1);
    setCleanAvatarCount(cleanAvatarCount + 1);
    setModalState(false);
  }

  return (<Modal title="创建管理员"
    getContainer={false}
    visible={modalState}
    footer={null}
    closable={false}>
    <OverallLoading spinning={spinning}></OverallLoading>
    <div className="avatar-box">
      <UploadSingleFileHandle
        avatarSize={64}
        uploadedResponseHandle={changedAdminAvatarHandle}
        cleanFileListCount={cleanAvatarCount}></UploadSingleFileHandle>
    </div>
    <CustomForm
      columns={adminerColumns}
      disabledKeys={disabledKeys}
      notShowKeys={notShowKeys}
      formKeyLabelObj={formKeyLabelObj}
      formKeySelectComponentsObj={formKeySelectComponentsObj}
      onFinish={onFinish}
      cancelHandle={cancelHandle}
      showDeleteButton={false}
      formInitialObj={adminerColumnInitialValues}
      initFormCount={initAdminFormCount}
      rulesObj={columnRulesObj}
      passwordKeys={passwordKeys}
    ></CustomForm>

    <AdminerRequestPost
      requestCount={createRequestCount}
      reqObject={createAdminObj.current}
      responseDataHandle={createResponseDataHandle}></AdminerRequestPost>

  </Modal>)
}

AdminCreateModal.defaultProps = {
  modalState: false,
  setModalState: (boolVal) => { console.log(boolVal); }
}

export default AdminCreateModal;
