import React from 'react';
import { CustomSelect } from '../base_components/select_component';

const adminerColumns = ["id", "name", "mobile", "email", "wx_openid", "password", "people_id",
  "address", "title", "avatar", "add_time", "update_time", "on_job"];

const adminerColumnInitialValues = {"id": "0", "name": "", "mobile": "", "email": "", "password":"", "people_id": "",
"address": "", "title": "", "add_time": "", "update_time": "", "on_job": true}

const disabledKeys = [
  "id", "wx_openid", "mobile", "avatar", "add_time", "update_time",
];

const notShowKeys = ["avatar", "wx_openid", "title", "password"];

const passwordKeys = ["password"];

const columnRulesObj = {
  "mobile": [{ required: true, min: 10, message: '请输入正确的手机',}], 
  "password": [{required: true, min: 6, message: '密码最少6位'}]};

const formKeyLabelObj = {
  "id": "ID", "name": "名称", "mobile": "手机", "email": "E-Mail", "password": "密码", "people_id": "身份证",
  "address": "地址", "title": "职务", "add_time": "加入日期", "update_time": "更新日期", "on_job": "在职"
};

const SelectBooleanOnJob = (<CustomSelect
  options={[{ value: true, label: '在职' }, { value: false, label: '离职' },]} />);
// 1: super_admin, 2: admin
const SelectTitle = (<CustomSelect options={[{ value: 1, label: 'super_admin' }, { value: 2, label: 'admin' },]} />);
const formKeySelectComponentsObj = {
  on_job: SelectBooleanOnJob,
  title: SelectTitle
};

export {
  adminerColumns,
  adminerColumnInitialValues,
  disabledKeys,
  notShowKeys,
  formKeyLabelObj,
  passwordKeys,
  columnRulesObj,
  formKeySelectComponentsObj
};
