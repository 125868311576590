import React, { useState } from 'react';
import { Modal, Button, Upload, message } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { sendAxiosRequest } from '../apis/sendRequest';

const uploadTaobaoCsvFilesApi = 'records-admin/v1/taobaopurchaserecord';
const initUploadTaobaoCsvFilesApi = 'records-admin/v1/inittaobaopurchaserecord';

// 上传淘宝订单 csv 文件模态框

const UploadTaobaoCsvFilesModal = ({ visible, setVisible, visibleNum, uploadedHandle }) => {
    const [csvFilesList, setCsvFilesList] = useState([])
    const [errorTips, setErrorTips] = useState('')
    const [loadingState, setLoadingState] = useState(false)

    const uploadTaobaoCsvFiles = () => {
        // 上传淘宝 csv 文件
        setErrorTips('')
        if (csvFilesList.length !== 2) {
            setErrorTips('必须选择淘宝 订单、商品 2个csv文件')
            return
        }
        const formdata = new FormData()
        let i = 1
        csvFilesList.forEach(file => {
            formdata.append(`file${i}`, file);
            i = i + 1
        });
        // console.log(csvFilesList)
        setLoadingState(true)
        return new Promise((resolve, reject) => {
            sendAxiosRequest(uploadTaobaoCsvFilesApi, 'post', {}, formdata, 500000).then(response => {
                console.log(response)
                if (response.code && response.code === 1000) {
                    message.success('淘宝订单 CSV 文件上传成功!')
                    setVisible(0)
                    resolve(true)
                } else {
                    message.error('淘宝订单 CSV 文件上传失败...')
                    reject(false)
                }
                setLoadingState(false)
                uploadedHandle()
            }).catch(err => {
                message.error('淘宝订单 CSV 文件上传失败, 请检查网络或文件...')
                console.log(err)
                reject(false)
                setLoadingState(false)
            })
        })
    }

    const props = {
        onRemove: file => {
            const copyFiles = [].concat(csvFilesList)
            const index = copyFiles.indexOf(file)
            copyFiles.splice(index, 1)
            setCsvFilesList(copyFiles)
            console.log(copyFiles)
        },
        onChange: (info) => {
            const fileList = [ ...info.fileList ]
            // console.log(fileList)
            const uploadFilesList = []
            fileList.map(file => {
                // 选择的文件列表赋值给 CsvFilesList
                // console.log(file)
                uploadFilesList.push(file.originFileObj)
            })
            setCsvFilesList(uploadFilesList)
        },
        beforeUpload: (file) => {
            // const copyFiles = [].concat(csvFilesList)
            // copyFiles.push(file)
            // setCsvFilesList(copyFiles)
            return false;
        },
        filesList: [],
      };

    return (
        <Modal
            visible={visible === visibleNum}
            title="上传淘宝订单CSV文件"
            closable={false}
            footer={[
                <Button key="upload_csv" onClick={uploadTaobaoCsvFiles} loading={loadingState}>上传</Button>,
                <Button key="cancel_modal" onClick={() => { setVisible(0) }} loading={loadingState}>取消</Button>
            ]}
        >
            <Upload {...props} maxCount={2} multiple={true} accept=".csv">
                <Button icon={<UploadOutlined />}>Select Csv File</Button>
            </Upload>
            {errorTips.length > 0 ? (<div><br></br><p style={{color: 'red'}}>* {errorTips}</p></div>) : null}
        </Modal>
    )
};

UploadTaobaoCsvFilesModal.defaultProps = {
    visible: 0,
    setVisible: (val) => console.log(val),
    visibleNum: 1,
    uploadedHandle: () => console.log('Uploaded')
};


// 初始化上传淘宝订单 csv 文件模态框

const InitUploadTaobaoCsvFilesModal = ({ visible, setVisible, visibleNum, uploadedHandle }) => {
    const [csvFilesList, setCsvFilesList] = useState([])
    const [errorTips, setErrorTips] = useState('')
    const [loadingState, setLoadingState] = useState(false)

    const uploadTaobaoCsvFiles = () => {
        // 上传淘宝 csv 文件
        setErrorTips('')
        if (csvFilesList.length !== 2) {
            setErrorTips('必须选择淘宝 订单、商品 2个csv文件')
            return
        }
        const formdata = new FormData()
        let i = 1
        csvFilesList.forEach(file => {
            formdata.append(`file${i}`, file);
            i = i + 1
        });
        // console.log(csvFilesList)
        setLoadingState(true)
        return new Promise((resolve, reject) => {
            sendAxiosRequest(initUploadTaobaoCsvFilesApi, 'post', {}, formdata, 200000).then(response => {
                console.log(response)
                if (response.code && response.code === 1000) {
                    message.success('初始化批量上传淘宝订单 CSV 文件成功!');
                    setVisible(0);
                    resolve(true);
                } else {
                    message.error('淘宝订单 CSV 文件上传失败...')
                    reject(false)
                }
                setLoadingState(false);
                uploadedHandle();
            }).catch(err => {
                message.error('淘宝订单 CSV 文件上传失败, 请检查网络或文件...')
                console.log(err);
                reject(false);
                setLoadingState(false);
            })
        })
    }

    const props = {
        onRemove: file => {
            const copyFiles = [].concat(csvFilesList)
            const index = copyFiles.indexOf(file)
            copyFiles.splice(index, 1)
            setCsvFilesList(copyFiles)
            console.log(copyFiles)
        },
        onChange: (info) => {
            const fileList = [ ...info.fileList ]
            // console.log(fileList)
            const uploadFilesList = []
            fileList.map(file => {
                // 选择的文件列表赋值给 CsvFilesList
                // console.log(file)
                uploadFilesList.push(file.originFileObj)
            })
            setCsvFilesList(uploadFilesList)
        },
        beforeUpload: (file) => {
            // const copyFiles = [].concat(csvFilesList)
            // copyFiles.push(file)
            // setCsvFilesList(copyFiles)
            return false;
        },
        filesList: [],
      };

    return (
        <Modal
            visible={visible === visibleNum}
            title="上传淘宝订单CSV文件"
            closable={false}
            footer={[
                <Button key="upload_csv" onClick={uploadTaobaoCsvFiles} loading={loadingState}>上传</Button>,
                <Button key="cancel_modal" onClick={() => { setVisible(0) }} loading={loadingState}>取消</Button>
            ]}
        >
            <Upload {...props} maxCount={2} multiple={true} accept=".csv">
                <Button icon={<UploadOutlined />}>Select Csv File</Button>
            </Upload>
            {errorTips.length > 0 ? (<div><br></br><p style={{color: 'red'}}>* {errorTips}</p></div>) : null}
        </Modal>
    )
};

InitUploadTaobaoCsvFilesModal.defaultProps = {
    visible: 0,
    setVisible: (val) => console.log(val),
    visibleNum: 1,
    uploadedHandle: () => console.log('Uploaded')
};

export { UploadTaobaoCsvFilesModal, InitUploadTaobaoCsvFilesModal };
