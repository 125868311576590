import React, { useState, useEffect } from 'react';
import { List, Button, message } from 'antd';

import LoadMore from '../base_components/load_more_component';
import ImageZoom from '../base_components/image_zoom';
import AdminInfoEdit from './admin_info_edit';
import { AdminerRequestGet } from './adminers_requests';

// 管理员列表
const adminTitles = ['未知', 'super-admin', 'admin'];

const AdminersLists = ({ selectAdminHandle, queryAdminsParams }) => {
  const [adminsLists, setAdminsLists] = useState([]);
  const [adminInfoModalState, setAdminInfoModalState] = useState(false); // 显示管理员详情对话框
  const [selectedAdminObj, setSelectedAdminObj] = useState({});

  const [getAdminsCount, setGetAdminsCount] = useState(0);
  const [showLoadMoreButton, setShowLoadmoreButton] = useState(true);

  useEffect(() => {
    // 重新查询
    setAdminsLists([]);
    sendGetAdminsRequestHandle();
  }, [queryAdminsParams]);

  const selectedAdminHandle = (adminObj) => {
    // 选中管理员后 操作
    console.log(adminObj);
    setSelectedAdminObj(adminObj);
    setAdminInfoModalState(true);
  }

  const getAdminsResponseHandle = (respObj) => {
    if (Object.keys(respObj).includes('code')
      && respObj.code === 1000
      && Object.keys(respObj).includes('name')) {
      setAdminsLists([respObj]);
      setShowLoadmoreButton(false);
    } else {
      // message.error('Error: network...');
      message.error('Error: 网络错误...');
      setAdminsLists([]);
      setShowLoadmoreButton(true);
    }
  };

  const sendGetAdminsRequestHandle = () => {
    // 发送获取管理员列表的请求
    setGetAdminsCount(getAdminsCount + 1);
    setShowLoadmoreButton(false);
  };

  return (<>
    <List
      itemLayout="vertical"
      loadMore={<LoadMore sendRequest={sendGetAdminsRequestHandle} showLoadMoreButton={showLoadMoreButton} />}
      dataSource={adminsLists}
      renderItem={item => (
        <List.Item
          actions={[
            <Button onClick={() => selectAdminHandle(item)} type="link">选择</Button>,
            <Button onClick={() => selectedAdminHandle(item)} type="link">详情</Button>]}
        >
          {/* <Skeleton avatar title={false} loading={item.loading} active> */}
          <List.Item.Meta
            avatar={<ImageZoom src={item.avatar} width={64}></ImageZoom>}
            title={`姓名: ${item.name}, 手机: ${item.mobile}, 住址: ${item.address};`}
            description={`职务: ${adminTitles[item.title]}`}
          />
          {/* </Skeleton> */}
        </List.Item>
      )}
    />

    <AdminerRequestGet
      requestCount={getAdminsCount}
      reqParams={queryAdminsParams}
      responseDataHandle={getAdminsResponseHandle}></AdminerRequestGet>

    <AdminInfoEdit
      adminObj={selectedAdminObj}
      modalState={adminInfoModalState}
      setModalState={setAdminInfoModalState}></AdminInfoEdit>
  </>)
};

AdminersLists.defaultProps = {
  selectAdminHandle: (adminObj) => { console.log(adminObj); },
  queryAdminsParams: {}
};

export default AdminersLists;
