import React, { useState, useEffect } from 'react';
import { Space, Upload, Button, message, Row, Col } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';

// import Zmage from 'react-zmage';

// 上传单张图片到服务器操作

import ImageZoom from '../base_components/image_zoom';
import customConfig from '../../custom_config';
import { getToken } from '../../assets/common_funcs/token_handle';

const UploadSingleFileHandle = ({
  oldImageUrl,
  avatarSize,
  uploadedResponseHandle,
  fileKey,
  cleanFileListCount,
}) => {
  const [showUploadButton, setShowUploadButton] = useState(true);
  const [fileList, setFileList] = useState([]);
  const [uploadedFileUrl, setUploadedFileUrl] = useState('');

  const [showOldImageUrl, setShowOldImageUrl] = useState(true);

  useEffect(() => {
    setShowUploadButton(fileList.length === 0);
  }, [fileList]);

  useEffect(() => {
    // 清空fileList
    setFileList([]);
  }, [cleanFileListCount]);

  useEffect(() => {
    // oldImageUrl 更新后删除 uploadedFileUrl
    setFileList([]);
    setUploadedFileUrl('');
    setShowOldImageUrl(true);
  }, [oldImageUrl]);

  const uploadSingleFileProps = {
    name: 'upload-single-file',
    action: `${customConfig.apiBaseUrl}/files-handle/v1/uploadfiles`,
    headers: {
      authorization: getToken(),
    },
    fileList: fileList,
    onChange: (info) => {
      setFileList(info.fileList.slice()); // Note: A new object must be used here!!!
      setShowOldImageUrl(false);
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);

        const respObj = { ...info.file.response };
        uploadedResponseHandle(respObj, fileKey);

        // 更新 uploadedFileUrl
        if (Object.keys(respObj).includes('image_cos_name_url') && respObj.image_cos_name_url.length > 10) {
          setUploadedFileUrl(respObj.image_cos_name_url);
        }
      } else if (info.file.status === 'error') {
        message.error(`Error: ${info.file.name} file upload failed.`);
        message.error(`Error: ${info.file.name} 文件上传失败...`);
        setFileList([]);
      }
    },
  };

  const deleteCurrentImage = () => {
    // 删除当前图片
    uploadedResponseHandle({}, fileKey);
    setUploadedFileUrl('');
    setFileList([]);
    setShowOldImageUrl(false);
  }

  return (
    <div>
      <Space direction="vertical">
        {/* <img alt="Image" height={`${avatarSize}px`}
          src={showOldImageUrl ? oldImageUrl : uploadedFileUrl} /> */}
        <ImageZoom src={showOldImageUrl ? oldImageUrl : uploadedFileUrl} width={avatarSize}></ImageZoom>
        <Row gutter={10}>
          <Col span={12}>
            <div style={{ textAlign: 'left' }}>
              <Upload {...uploadSingleFileProps}>
                {showUploadButton ? (<Button size="small"><UploadOutlined /> 更新</Button>) : null}
              </Upload>
            </div>
          </Col>
          <Col span={12}>
            {uploadedFileUrl.length > 10 || oldImageUrl.length > 10
              ? (<Button onClick={deleteCurrentImage} size="small"><DeleteOutlined /> 删除</Button>) : null}
          </Col>
        </Row>
      </Space>
    </div>
  )
};

UploadSingleFileHandle.defaultProps = {
  oldImageUrl: '',
  avatarSize: 96,
  uploadedResponseHandle: (responseObj, fileKey) => {
    // 上传成功后, 操作 response
    console.log(responseObj, fileKey);
  },
  fileKey: 'image1',
  cleanFileListCount: 0,
};

export default UploadSingleFileHandle;
