import React, {  useState, useRef } from 'react';
import { Modal, message } from 'antd';

import OverallLoading from '../overall_loading';
import { SuperAdminerRequestPut, AdminerRequestDelete } from './adminers_requests';
import { CustomForm } from '../base_components/form_component';
import {
  adminerColumns,
  adminerColumnInitialValues,
  disabledKeys,
  formKeyLabelObj,
  formKeySelectComponentsObj
} from './admin_columns_config';
// 使用 sts 客户端直接上传腾讯云
import StsUploadFile from '../upload_files/sts_upload_file';

// 超级管理员详细信息和编辑

const AdminInfoEdit = ({ adminObj, modalState, setModalState }) => {
  const [spinning, setSpinning] = useState(false);
  const [cleanAvatarCount, setCleanAvatarCount] = useState(0);
  const [updateRequestCount, setUpdateRequestCount] = useState(0);
  const [deleteRequestCount, setDeltetRequestCount] = useState(0);
  const [initAdminFormCount, setInitAdminFormCount] = useState(0);
  const reqObject = useRef({});

  const uploadedAvatarObj = useRef({});

  const notShowKeys = ["avatar", "wx_openid"];

  const changedAdminAvatarHandle = (uploadedFileResponseObj) => {
    // 更新顾客头像后操作
    console.log(uploadedFileResponseObj);
    const deepcopyObj = { ...uploadedFileResponseObj };
    if (Object.keys(deepcopyObj).includes('code')
      && Object.keys(deepcopyObj).includes('image_cos_name')
      && deepcopyObj.code === 1000) {
      uploadedAvatarObj.current = { avatar: deepcopyObj.image_cos_name };
    } else {
      // 上传失败 赋值空对象
      uploadedAvatarObj.current = {};
    }
  };

  const updateResponseDataHandle = (respObj) => {
    // 更新管理员 response 结果
    setSpinning(false);
    console.log(respObj);
    if (Object.keys(respObj).includes('code')
      && respObj.code === 1000) {
      message.success('Success update admin!');
      message.success('修改成功!');
      cancelHandle();   // 更新成功后关闭管理员详情编辑对话框
    } else {
      message.error('Error...');
      message.error('网络错误, 请检查...');
    }
  };

  const deleteResponseDataHandle = (respObj) => {
    // 删除管理员 response 结果
    console.log(respObj);
    cancelHandle();
  };

  const onFinish = (values) => {
    // 更新管理员详细信息
    console.log(values);
    reqObject.current = { ...values, ...uploadedAvatarObj.current };
    setSpinning(true);
    setUpdateRequestCount(updateRequestCount + 1);    // 发送更新请求
  };

  const cancelHandle = () => {
    // 取消操作
    console.log('Cancel..');
    setCleanAvatarCount(cleanAvatarCount + 1);  // 清空显示的头像
    uploadedAvatarObj.current = {};   // 清空上传头像 obj
    setInitAdminFormCount(initAdminFormCount + 1);  // 清空form内容(初始化form内容)
    setModalState(false);   // 关闭对话框
  };

  const deleteAdminHandle = (adminObj) => {
    // 删除管理员操作
    setDeltetRequestCount(deleteRequestCount + 1);
  }

  return (
    <Modal title="管理员详情"
      getContainer={false}
      visible={modalState}
      footer={null}
      closable={false}>
      <OverallLoading spinning={spinning}></OverallLoading>
      <div className="avatar-box">
        <StsUploadFile
          oldImageUrl={adminObj.avatar}
          avatarSize={64}
          uploadedResponseHandle={changedAdminAvatarHandle}
          cleanFileListCount={cleanAvatarCount} ></StsUploadFile>
      </div>
      <CustomForm
        columns={adminerColumns}
        disabledKeys={disabledKeys}
        notShowKeys={notShowKeys}
        formKeyLabelObj={formKeyLabelObj}
        formKeySelectComponentsObj={formKeySelectComponentsObj}
        onFinish={onFinish}
        cancelHandle={cancelHandle}
        deleteHandle={deleteAdminHandle}
        showDeleteButton={true}
        updateFormObj={adminObj}
        formInitialObj={adminerColumnInitialValues}
        initFormCount={initAdminFormCount}
      ></CustomForm>

      <SuperAdminerRequestPut
        adminerId={adminObj.id}
        requestCount={updateRequestCount}
        reqObject={reqObject.current}
        responseDataHandle={updateResponseDataHandle}></SuperAdminerRequestPut>

      <AdminerRequestDelete
        adminId={adminObj.id}
        requestCount={deleteRequestCount}
        responseDataHandle={deleteResponseDataHandle}></AdminerRequestDelete>
    </Modal>
  )
};

AdminInfoEdit.defaultProps = {
  adminObj: {},
  modalState: false,
  setModalState: (booleanValue) => { console.log(`Close Modal ${booleanValue}...`); }
};

export default AdminInfoEdit;
