import React from 'react';

import SEO from "../components/seo";
import SiteLayout from '../components/site_layout';
import Adminers from '../components/adminers';

const AdminsPage = () => (
  <SiteLayout>
    <SEO title="Admin" />
    <Adminers></Adminers>
  </SiteLayout>
);

export default AdminsPage;
