import React, { useState, useEffect } from 'react';
import { Button, Space, Tooltip, message, Row, Col } from 'antd';
import { ReloadOutlined } from '@ant-design/icons'

import AdminersLists from './adminers_lists';
import SuperAdminersList from './super_adminers_list';
import { AdminerRequestGet } from './adminers_requests';
import AdminCreateModal from './admin_create';
import { InitUploadTaobaoCsvFilesModal } from '../modals/upload_taobao_csv';

// 管理员页面

const Adminers = () => {
  const [createAdminModalState, setCreateAdminModalState] = useState(false);
  const [queryAdminsParams, setQueryAdminSParams] = useState({});
  const [getAdminSelfCount, setGetAdminSelfCount] = useState(0);
  const [adminTitle, setAdminTitle] = useState(2);

  const [visible, setVisible] = useState(0);

  useEffect(() => {
    // 加载时确定是不是超级管理员
    setTimeout(() => {
      setGetAdminSelfCount(getAdminSelfCount + 1);
    }, 1000);
  }, []);

  const reloadAdmins = () => {
    setQueryAdminSParams({ id: 0 });
    setQueryAdminSParams({});
  };

  const getAdminSelfInfoResponseHandle = (respObj) => {
    if (Object.keys(respObj).includes('code')
      && respObj.code === 1000
      && Object.keys(respObj).includes('title')) {
        setAdminTitle(respObj.title)
    } else {
      // message.error('Error: network...');
      message.error('Error: 错误, 请登陆...');
      setAdminTitle(2);
    }
  }

  return (
    <>
      <Space direction="vertical">
        <Row>
          <Col span={24}>
            <h4>Admins Manage</h4>
          </Col>
        </Row>
        <Row>
          <Col span={14}>
            <Space>
              <Button type="default" onClick={() => setCreateAdminModalState(true)}>创建管理员</Button>
              <Tooltip title="刷新">
                <Button onClick={reloadAdmins} shape="circle" icon={<ReloadOutlined />}></Button>
              </Tooltip>
            </Space>
          </Col>
          <Col span={10}>
            <div style={{ width: '100%', height: 'auto' }}>
              <Tooltip title="初始化导入淘宝订单">
                <Button style={{ float: 'right' }} type="link" onClick={() => setVisible(1)}>淘宝csv文件</Button>
              </Tooltip>
            </div>
          </Col>
        </Row>
      </Space>
      <br></br><hr></hr>

      <AdminerRequestGet
        requestCount={getAdminSelfCount}
        reqParams={{}}
        responseDataHandle={getAdminSelfInfoResponseHandle}
      ></AdminerRequestGet>

      {
        adminTitle === 1
        ? <SuperAdminersList queryAdminsParams={queryAdminsParams} /> 
        : <AdminersLists />
      }

      <AdminCreateModal modalState={createAdminModalState} setModalState={setCreateAdminModalState} />

      <InitUploadTaobaoCsvFilesModal
        visible={visible}
        setVisible={setVisible}
        visibleNum={1}
      ></InitUploadTaobaoCsvFilesModal>
    </>
  )
}

export default Adminers;

