import React, { useRef, useState, useEffect } from 'react';
import { Button, Upload, Space, Row, Col, message } from 'antd';
import { UploadOutlined, DeleteOutlined } from '@ant-design/icons';
// import Cos from 'cos-js-sdk-v5';
//  install: npm i cos-js-sdk-v5 --save

import ImageZoom from '../base_components/image_zoom';
import { StsTemporaryKeyRequestPost } from './upload_file_requests';
import { cosConfig } from './sts_funcs_config';

// sts 上传文件到腾讯云服务器, 服务器端生成临时密钥，浏览器直接上传文件到腾讯云cos

const StsUploadFile = ({
  oldImageUrl,
  avatarSize,
  uploadedResponseHandle,
  fileKey,
  cleanFileListCount,
}) => {
  // const Cos = require('cos-js-sdk-v5');
  const selectedFileObj = useRef({});
  const stsTemporaryObj = useRef({});
  const cos_name = useRef('');
  const [uploadedFileUrl, setUploadedFileUrl] = useState('');
  const [temporaryKeyRequestCount, setTemporaryKeyRequestCount] = useState(0);
  const temporaryKeyRequestObj = useRef({});

  const [showOldImageUrl, setShowOldImageUrl] = useState(true);
  const [showUploadButton, setShowUploadButton] = useState(true);

  useEffect(() => {
    // 清空fileList
    // selectedFileObj.current = [];
    deleteCurrentImage();
  }, [cleanFileListCount]);

  useEffect(() => {
    // oldImageUrl 更新后删除 uploadedFileUrl
    // setFileList([]);
    selectedFileObj.current = {};
    setUploadedFileUrl('');
    setShowOldImageUrl(true);
  }, [oldImageUrl]);

  useEffect(() => {
    // 是否显示上传按钮
    setShowUploadButton(Object.keys(selectedFileObj.current).length === 0);
  }, [selectedFileObj.current]);

  const getAuthorization = function (options, callback) {
    // 验证函数
    const stsObj = { ...stsTemporaryObj.current };
    const credentials = stsObj.credentials;
    callback({
      TmpSecretId: credentials.tmpSecretId,
      TmpSecretKey: credentials.tmpSecretKey,
      XCosSecurityToken: credentials.sessionToken,
      StartTime: stsObj.startTime, // 时间戳，单位秒，如：1580000000，建议返回服务器时间作为签名的开始时间，避免用户浏览器本地时间偏差过大导致签名错误
      ExpiredTime: stsObj.expiredTime, // 时间戳，单位秒，如：1580000900
    });
  };

  const temporaryKeyResponseDataHandle = (respObj) => {
    // 获取临时密钥后操作
    console.log(respObj);
    if (!Object.keys(respObj).includes('image_cos_name')
      && !Object.keys(respObj).includes('sts_obj')) {
      console.log('Get temporary key error...');
      return;
    }
    stsTemporaryObj.current = respObj.sts_obj;
    cos_name.current = respObj.image_cos_name;

    // 上传到腾讯云 bucket
    if (typeof window === 'undefined') {
      return;
    }
    const Cos = require('cos-js-sdk-v5');
    const cos = new Cos({ getAuthorization: getAuthorization });
    cos.putObject({
      ...cosConfig,
      Key: cos_name.current,                        /* 必须 */
      Body: selectedFileObj.current,                // 上传文件对象
      onProgress: function (progressData) {
        console.log(JSON.stringify(progressData));
      }
    }, function (err, data) {
      console.log(err || data);
      if (data) {
        console.log(data);
        setUploadedFileUrl(respObj.image_cos_name_url);
        setShowOldImageUrl(false);
        // 回调给父组件
        uploadedResponseHandle(respObj, fileKey);
        message.success('图片上传成功!');
      } else {
        deleteCurrentImage();  //上传失败删除图片
        message.error('图片上传失败...');
      }
    });
  };

  const uploadSingleFileProps = {
    // 自定义上传文件操作
    fileList: [],
    beforeUpload: file => {
      selectedFileObj.current = file;
      setTemporaryKeyRequestCount(temporaryKeyRequestCount + 1);
      return false;
    },
  }

  const deleteCurrentImage = () => {
    // 删除当前图片
    uploadedResponseHandle({}, fileKey);
    setUploadedFileUrl('');
    // setFileList([]);
    selectedFileObj.current = {};
    setShowOldImageUrl(false);
  }

  return (
    <div>
      <Space direction="vertical">
        <ImageZoom src={showOldImageUrl ? oldImageUrl : uploadedFileUrl} width={avatarSize}></ImageZoom>
        <Row gutter={10}>
          <Col span={12}>
            <div style={{ textAlign: 'left' }}>
              <Upload {...uploadSingleFileProps}>
                {showUploadButton ? (<Button size="small"><UploadOutlined /> 更新</Button>) : null}
              </Upload>
            </div>
          </Col>
          <Col span={12}>
            {uploadedFileUrl.length > 10 || oldImageUrl.length > 10
              ? (<Button onClick={deleteCurrentImage} size="small"><DeleteOutlined /> 删除</Button>) : null}
          </Col>
        </Row>

        <StsTemporaryKeyRequestPost requestCount={temporaryKeyRequestCount}
          reqObject={temporaryKeyRequestObj.current}
          responseDataHandle={temporaryKeyResponseDataHandle}></StsTemporaryKeyRequestPost>
      </Space>
    </div>
  )
};

StsUploadFile.defaultProps = {
  oldImageUrl: '',
  avatarSize: 96,
  uploadedResponseHandle: (responseObj, fileKey) => {
    // 上传成功后, 操作 response
    console.log(responseObj, fileKey);
  },
  fileKey: 'image1',
  cleanFileListCount: 0,
};

export default StsUploadFile;
